<template>
  <div class="filter-panel container-fluid">
    <div class="row">
      <div class="col">
        <div class="text-orange lead font-display text-center">
          <h4><br />Filters</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-end">
        <a class="link-orange" @click="resetFilters"> Reset Filters </a>
      </div>
    </div>
    <hr class="dotted-spacer hy-2" />
    <div class="row">
      <div class="col pb-4">
        <p>Channels</p>
        <BaseMultiselect
          v-model="filter.channels"
          :multiple="true"
          :taggable="false"
          :options="channels"
          track-by="name"
          name="channels"
        />
      </div>
    </div>
    <div class="row">
      <div class="col pb-4">
        <p>Locations</p>
        <BaseMultiselect
          v-model="filter.locations"
          :multiple="true"
          :taggable="false"
          :options="locations"
          track-by="name"
          name="locations"
        />
      </div>
    </div>
    <div class="row">
      <div class="col pb-4">
        <p>Tags</p>
        <BaseMultiselect
          v-model="filter.tags"
          :multiple="true"
          :taggable="false"
          :options="tags"
          track-by="name"
          name="tags"
        />
      </div>
    </div>

    <div class="row">
      <div class="col pb-4">
        <p>Spokespersons</p>
        <BaseMultiselect
          v-model="filter.spokespersons"
          :multiple="true"
          :taggable="false"
          :options="spokespersons"
          track-by="name"
          name="spokespersons"
        />
      </div>
    </div>

    <div class="row">
      <div class="col pb-4">
        <p>Owners</p>
        <BaseMultiselect
          v-model="filter.owners"
          :multiple="true"
          :taggable="false"
          :options="teamMembers"
          track-by="name"
          name="owners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { LocalGetSelf, GetUsers } from "@gql/user";
import { GetChannelsForFilter, GetTags } from "@gql/tag";
import { GetActivityStatuses } from "@gql/activityStatus";

import { merge } from "lodash";

export default {
  name: "FilterPanel",
  props: {
    parentFilter: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      filter: {
        spokespersons: [],
        locations: [],
        channels: [],
        tags: [],
        owners: [],
        isExternal: true,
        isInternal: true,
        include_all: true,
        status: {
          name: {
            in: ["approved", "proposed", "readyforapproval", "delivered"],
          },
        },
      },
    };
  },
  computed: {
    stickyFilters() {
      return Number.parseInt(process.env.VUE_APP_CALENDAR_STICKY_FILTERS) === 1;
    },
    totalFilterCount() {
      let count = 0;
      if (this.filter.channels) count++;
      if (this.filter.tags && this.filter.tags.length > 0) count++;
      if (this.filter.spokespersons && this.filter.spokespersons.length > 0) count++;
      if (this.filter.activityStatuses && this.filter.activityStatuses.length > 0) count++;
      if (this.filter.owners) count++;

      return count;
    },
  },
  watch: {
    filter: {
      handler(newVal, oldVal) {
        if (!newVal.owner || !newVal.owner[0]) {
          delete newVal.owner;
        }

        if (this.stickyFilters) {
          this.$log.debug(
            "FilterPanel - Setting LocalStorage Filters",
            process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY,
            newVal
          );
          window.localStorage.setItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY, JSON.stringify(newVal));
        }

        this.$root.$emit("calendarFilterChange", newVal);

        this.$root.$emit("filterDefault", this.totalFilterCount);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.stickyFilters && window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY)) {
      const f = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_CALENDAR_FILTERS_LOCALSTORAGE_KEY));
      this.$log.debug("FilterPanel - Found Sticky filters from LocalStorage", f);
      this.filter = f;
    }

    if (this.parentFilter) {
      merge(this.filter, this.parentFilter);
    }

    this.$root.$on("resetPanelFilters", this.resetFilters);
    this.$root.$on("updateFilterValues", this.updateFiltersFromParent);
  },
  apollo: {
    user: LocalGetSelf,
    tags: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "generic" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update(data) {
        this.$log.debug("Got Tags from API: ", data);
        return data.tags;
      },
    },
    spokespersons: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            categories: { some: { name: { equals: "spokesperson" } } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: ({ tags }) => tags,
    },
    locations: {
      query: GetTags,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            type: { name: { equals: "location" } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: ({ tags }) => tags,
    },
    teamMembers: {
      query: GetUsers,
      variables() {
        return {
          where: {
            company: {
              id: { equals: this.user.company.id },
            },
            role: { name: { in: ["ADMIN", "TEAM"] } },
          },
        };
      },
      skip() {
        return !this.user;
      },
      update: (data) => data.users.map((user) => ({ ...user, label: user.name })),
    },
    activityStatuses: {
      query: GetActivityStatuses,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
    },
    channels: {
      query: GetChannelsForFilter,
      variables() {
        return {
          companyId: this.user.company.id,
          orderBy: { label: "asc" },
        };
      },
      update: ({ tags }) => tags,
      error(error) {
        this.$log.error("There was an error", error);
      },
      skip() {
        return !this.user;
      },
    },
  },
  methods: {
    // closes filter panel
    closePanel() {
      this.$emit("closePanel", {});
    },
    // Resets all filters
    resetFilters() {
      this.$log.debug("FilterPanel - Resetting filter");

      // Reset filter object
      this.filter = null;
      this.filter = {
        spokespersons: [],
        locations: [],
        channels: [],
        tags: [],
        owners: [],
        isExternal: true,
        isInternal: true,
        include_all: true,
        status: {
          name: {
            in: ["approved", "proposed", "readyforapproval", "delivered"],
          },
        },
      };
      this.$root.$emit("calendarFilterChange", this.filter);
      this.$root.$emit("filterDefault", this.totalFilterCount);
    },
    /**
     * Update filters based on parent component
     */
    updateFiltersFromParent(filter) {
      this.$log.debug("FilterPanel - Update filters from parent", filter);
      this.filter = filter;
    },
  },
};
</script>
